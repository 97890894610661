import { DateView, UseLocation } from "nystem-components";

const getQueryValue = (query, saveId) => {
  if (!saveId) return "";
  const reg = `\\&${saveId}=([^\\s&]+)`;
  const [, value = ""] = query.match(new RegExp(reg, "im")) || [];
  return value !== "" ? parseFloat(value || 0) : undefined;
};

const DateExposedView = ({ model, view }) => {
  const { search } = UseLocation();

  return (
    <DateView
      view={view}
      model={model}
      value={getQueryValue(search, model.saveId) || model.fallback}
    />
  );
};
export default DateExposedView;
