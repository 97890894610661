import { ContentTypeRender, UseLocation, Wrapper } from "nystem-components";

const ViewLinkAdd2Query = ({ path, model, value }) => {
  const { className, item, contentType, close } = model;

  const url = `&popup.${model.view}.${value._id || ""}=${contentType}`;
  const { pathname, search } = UseLocation();
  const isMatch = search.includes(url);

  return (
    <Wrapper
      renderAs="a"
      href="/"
      className={[className, isMatch && "active"]}
      onClick={(event) => {
        event.preventDefault();
        let newState = `${pathname}${search || "?"}${url}`;
        if (close) newState = newState.replace(new RegExp(url, "g"), "");
        window.history.pushState({}, "", newState);
      }}
    >
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default ViewLinkAdd2Query;
