const parens = /\(([0-9+\-*/^ .]+)\)/; // Regex for identifying parenthetical expressions
const exp = /(\d+(?:\.\d+)?) ?\^ ?(\d+(?:\.\d+)?)/; // Regex for identifying exponentials (x ^ y)
const mul = /(\d+(?:\.\d+)?) ?\* ?(\d+(?:\.\d+)?)/; // Regex for identifying multiplication (x * y)
const div = /(\d+(?:\.\d+)?) ?\/ ?(\d+(?:\.\d+)?)/; // Regex for identifying division (x / y)
const add = /(\d+(?:\.\d+)?) ?\+ ?(\d+(?:\.\d+)?)/; // Regex for identifying addition (x + y)
const sub = /(\d+(?:\.\d+)?) ?- ?(\d+(?:\.\d+)?)/; // Regex for identifying subtraction (x - y)

function evaluate(expr) {
  if (isNaN(Number(expr))) {
    if (parens.test(expr)) {
      const newExpr = expr.replace(parens, (match, subExpr) => {
        return evaluate(subExpr);
      });
      return evaluate(newExpr);
    }
    if (exp.test(expr)) {
      const newExpr = expr.replace(exp, (match, base, pow) =>
        // eslint-disable-next-line no-restricted-properties
        Math.pow(Number(base), Number(pow))
      );
      return evaluate(newExpr);
    }
    if (mul.test(expr)) {
      const newExpr = expr.replace(mul, (match, a, b) => Number(a) * Number(b));
      return evaluate(newExpr);
    }
    if (div.test(expr)) {
      const newExpr = expr.replace(div, (match, a, b) => {
        if (b !== 0) return Number(a) / Number(b);
        throw new Error("Division by zero");
      });
      return evaluate(newExpr);
    }
    if (add.test(expr)) {
      const newExpr = expr.replace(add, (match, a, b) => Number(a) + Number(b));
      return evaluate(newExpr);
    }
    if (sub.test(expr)) {
      const newExpr = expr.replace(sub, (match, a, b) => Number(a) - Number(b));
      return evaluate(newExpr);
    }
    return expr;
  }
  return Number(expr);
}

function evalVal(value, refValue) {
  if (!value) return 0;
  if (typeof value !== "string") return value;

  return parseInt(
    /* eslint-disable no-eval */
    evaluate(
      value.replace(/([0-9]+)%/g, (str, p1) => {
        return 0.01 * parseInt(p1, 10) * refValue;
      })
    ),
    10
  );
}

const calculateSize = (item, model) => {
  const { width: iWidth, height: iHeight } = item;
  let { width: mWidth, height: mHeight } = model;

  if (!iWidth && !iHeight) return { width: mWidth, height: mHeight };
  if ((!mWidth && !mHeight) || item.ext === "svg")
    return { width: iWidth, height: iHeight };

  mWidth = evalVal(mWidth, window.innerWidth);
  mHeight = evalVal(mHeight, window.innerHeight);

  const iAspect = iWidth / iHeight;
  const mAspect = mWidth / mHeight;

  let height = (mHeight / iWidth) * iHeight;
  height = height > mHeight ? mHeight : height;

  let width = (mHeight / iHeight) * iWidth;
  width = parseInt(width > mWidth ? mWidth : width, 10);

  height = parseInt(iAspect > mAspect ? width / iAspect : height, 10);

  return { width, height, density: window.devicePixelRatio };
};

export default function (app) {
  app.image = { ...(app.image || {}), calculateSize };
}
