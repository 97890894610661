import { ContentTypeView, Wrapper, UseLocation } from "nystem-components";

const ReferenceExposedView = ({ model, view, path }) => {
  const { renderFormat, className, source, itemClassName } = model;
  const { search } = UseLocation();
  const value = search
    .split("&")
    .slice(1)
    .map((item) => {
      if (!item.startsWith("filter")) return false;
      return item.split("=")[1];
    })
    .filter((item) => item);

  const option = (item) => (
    <ContentTypeView
      key={item}
      contentType={source}
      baseView={view}
      format={renderFormat || "view"}
      id={item}
      params={view.params}
      className={itemClassName}
      onReference={(item) => view.event(item.event, { ...item, model, path })}
    />
  );

  return <Wrapper className={className}>{value.map(option)}</Wrapper>;
};
export default ReferenceExposedView;
