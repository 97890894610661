import {
  InputWrapper,
  DateSlider,
  UseSearch,
  RouterUseQueryStore,
} from "nystem-components";

const DateExposedRange = ({ model, view }) => {
  const [from, setFrom] = RouterUseQueryStore(model.saveIdFrom, "float");
  const [to, setTo] = RouterUseQueryStore(model.saveIdTo, "float");
  const { eventType } = model;

  const emitterByType = {
    view: view,
    baseView: view.baseView,
    baseViewBaseView: view.baseView?.baseView,
    baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
    baseViewBaseViewBaseViewBaseView:
      view.baseView?.baseView?.baseView?.baseView,
  };
  const emitter = emitterByType[eventType] || view.baseView.baseView.baseView;
  const sFrom = (from && to && (from < to ? from : to)) || model.from;
  const sTo = (from && to && (from > to ? from : to)) || model.to;
  UseSearch({ view: emitter, id: model.id, value: from && `>${sFrom}` });
  UseSearch({ view: emitter, id: model.id, value: to && `<${sTo}` });

  return (
    <InputWrapper model={model}>
      <DateSlider model={model} value={to || model.to} setValue={setTo} />
      <DateSlider model={model} value={from || model.from} setValue={setFrom} />
    </InputWrapper>
  );
};

//
export default DateExposedRange;
