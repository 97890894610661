import {
  RouterUseQueryStore,
  Wrapper,
  ContentTypeRender,
  UseSearch,
} from "nystem-components";

const ViewButtonReferenceToggle = ({ model, view, path, value }) => {
  const { item, className, renderAs, eventType } = model;
  const [val, setValue] = RouterUseQueryStore(`filter${value._id}`);

  const emitterByType = {
    view: view,
    baseView: view.baseView,
    baseViewBaseView: view.baseView?.baseView,
    baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
    baseViewBaseViewBaseViewBaseView:
      view.baseView?.baseView?.baseView?.baseView,
  };

  UseSearch({
    view: emitterByType[eventType] || view.baseView.baseView.baseView,
    id: model.sourceField,
    value: val,
    exact: model.exact,
  });

  return (
    <Wrapper
      className={[className, val && "selected"]}
      renderAs={renderAs}
      onClick={() => {
        setValue(val ? false : value._id);
      }}
    >
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};

export default ViewButtonReferenceToggle;
